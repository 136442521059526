import type { Components } from '@mui/material/styles/components';
import { createTheme, inputLabelClasses, tableCellClasses } from '@mui/material';
import { CheckboxIcon } from 'src/icons/checkbox-icon';
import { CheckboxCheckedIcon } from 'src/icons/checkbox-checked-icon';
import { CheckboxIntermediateIcon } from 'src/icons/checkbox-intermediate-icon';

// Used only to create transitions
const muiTheme = createTheme();

export const createComponents = (): Components => {
  return {
    MuiContainer: {
      styleOverrides: {
        root: {
          paddingLeft: '16px !important',
          paddingRight: '16px !important',
        },
      },
    },
    MuiAvatar: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 600,
          letterSpacing: 0,
        },
      },
    },
    MuiButton: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          textTransform: 'none',
          fontWeight: 'bold',
          '&::first-letter': {
            textTransform: 'capitalize',
          },
        },
        sizeSmall: {
          padding: '6px 16px',
        },
        sizeMedium: {
          padding: '8px 20px',
        },
        sizeLarge: {
          padding: '11px 24px',
        },
        textSizeSmall: {
          padding: '7px 12px',
        },
        textSizeMedium: {
          padding: '9px 16px',
        },
        textSizeLarge: {
          padding: '12px 16px',
        },
      },
    },
    MuiImageList: {
      styleOverrides: {
        root: {
          padding: 0,
          margin: 0,
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: 8,
        },
      },
    },
    MuiCardContent: {
      styleOverrides: {
        root: {
          padding: '16px 16px',
          '&:last-child': {
            paddingBottom: '16px',
          },
        },
      },
    },
    MuiCardHeader: {
      defaultProps: {
        titleTypographyProps: {
          variant: 'h6',
        },
        subheaderTypographyProps: {
          variant: 'body2',
        },
      },
    },
    MuiCheckbox: {
      defaultProps: {
        checkedIcon: <CheckboxCheckedIcon />,
        color: 'primary',
        icon: <CheckboxIcon />,
        indeterminateIcon: <CheckboxIntermediateIcon />,
      },
    },
    MuiChip: {
      styleOverrides: {
        root: {
          fontWeight: 500,
        },
      },
    },
    MuiCssBaseline: {
      styleOverrides: {
        '*': {
          boxSizing: 'border-box',
        },
        html: {
          MozOsxFontSmoothing: 'grayscale',
          WebkitFontSmoothing: 'antialiased',
          display: 'flex',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        body: {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          minHeight: '100%',
          width: '100%',
        },
        '#__next': {
          display: 'flex',
          flex: '1 1 auto',
          flexDirection: 'column',
          height: '100%',
          width: '100%',
        },
        '#nprogress': {
          pointerEvents: 'none',
        },
        '#nprogress .bar': {
          height: 3,
          left: 0,
          position: 'fixed',
          top: 0,
          width: '100%',
          zIndex: 2000,
        },
      },
    },
    MuiIconButton: {
      defaultProps: {
        size: 'small',
      },
      styleOverrides: {
        root: {
          fontSize: '1.2em',
          borderRadius: 8,
          padding: 8,
        },
      },
    },
    // MuiInputBase: {
    //   styleOverrides: {
    //     input: {
    //       '&::placeholder': {
    //         opacity: 1,
    //       },
    //     },
    //   },
    // },
    // MuiInput: {
    //   styleOverrides: {
    //     input: {
    //       fontSize: 14,
    //       fontWeight: 500,
    //       lineHeight: '24px',
    //     },
    //   },
    // },
    // MuiFilledInput: {
    //   styleOverrides: {
    //     root: {
    //       backgroundColor: 'transparent',
    //       borderRadius: 8,
    //       borderStyle: 'solid',
    //       borderWidth: 1,
    //       overflow: 'hidden',
    //       transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
    //       '&:before': {
    //         display: 'none',
    //       },
    //       '&:after': {
    //         display: 'none',
    //       },
    //     },
    //     input: {
    //       fontSize: 14,
    //       fontWeight: 500,
    //       lineHeight: '24px',
    //     },
    //   },
    // },
    // MuiOutlinedInput: {
    //   styleOverrides: {
    //     input: {
    //       fontSize: 14,
    //       fontWeight: 500,
    //       lineHeight: '24px',
    //     },
    //     notchedOutline: {
    //       transition: muiTheme.transitions.create(['border-color', 'box-shadow']),
    //     },
    //   },
    // },
    MuiOutlinedInput: {
      styleOverrides: {
        root: {
          borderRadius: 3,
        },
        input: {
          fontWeight: 500,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          fontWeight: 500,
          [`&.${inputLabelClasses.filled}`]: {
            transform: 'translate(12px, 18px) scale(1)',
          },
          [`&.${inputLabelClasses.shrink}`]: {
            [`&.${inputLabelClasses.standard}`]: {
              transform: 'translate(0, -1.5px) scale(0.85)',
            },
            [`&.${inputLabelClasses.filled}`]: {
              transform: 'translate(12px, 6px) scale(0.85)',
            },
            [`&.${inputLabelClasses.outlined}`]: {
              transform: 'translate(14px, -9px) scale(0.85)',
            },
          },
        },
      },
    },
    MuiLinearProgress: {
      styleOverrides: {
        root: {
          borderRadius: 3,
          overflow: 'hidden',
        },
      },
    },
    MuiLink: {
      defaultProps: {
        underline: 'hover',
      },
    },
    MuiListItemIcon: {
      styleOverrides: {
        root: {
          marginRight: '16px',
          minWidth: 'unset',
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundImage: 'none',
        },
      },
    },
    MuiPopover: {
      defaultProps: {
        elevation: 16,
      },
    },
    MuiRadio: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiSwitch: {
      defaultProps: {
        color: 'primary',
      },
    },
    MuiTab: {
      styleOverrides: {
        root: {
          fontSize: 14,
          fontWeight: 500,
          lineHeight: 1.71,
          minWidth: 'auto',
          // paddingLeft: 0,
          // paddingRight: 0,
          textTransform: 'none',
          '& + &': {
            marginLeft: 24,
          },
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: '15px 16px',
        },
      },
    },
    //@ts-ignore
    MuiTabPanel: {
      styleOverrides: {
        root: {
          paddingTop: '16px',
          paddingBottom: '16px',
          paddingLeft: 0,
          paddingRight: 0,
        },
      },
    },
    // @ts-ignore
    MuiDataGrid: {
      styleOverrides: {
        WebkitFontSmoothing: 'auto',
        root: {
          '& .MuiDataGrid-columnHeader': {
            borderBottom: 'none',
            fontSize: '12px',
            fontWeight: 900,
            lineHeight: 1.2,
            textTransform: 'uppercase',
          },
          '& .MuiDataGrid-toolbarContainer': {
            padding: '8px',
          },
          '& .MuiDataGrid-cell:focus': {
            outline: 'none',
          },
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none',
          },
          // MuiDataGrid-columnHeaderCheckbox
          '& .MuiDataGrid-row, & .MuiDataGrid-columnHeadersInner': {
            '& .MuiDataGrid-cell': {
              paddingLeft: '16px !important',
              paddingRight: '16px !important',
            },
            '& .MuiDataGrid-columnHeaderTitle': {
              paddingLeft: '8px !important',
            },
          },
        },
      },
    },
    MuiTableHead: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
          [`& .${tableCellClasses.root}`]: {
            borderBottom: 'none',
            fontSize: 12,
            fontWeight: 600,
            lineHeight: 1,
            letterSpacing: 0.5,
            textTransform: 'uppercase',
          },
          [`& .${tableCellClasses.paddingCheckbox}`]: {
            paddingTop: 4,
            paddingBottom: 4,
          },
        },
      },
    },
    MuiTextField: {
      defaultProps: {
        variant: 'outlined',
      },
    },
  };
};
