import { ChemicalTest, ObservationTest, RecommendationOrderCard } from 'src/types/chemical';
import {
  ORDERED_RECOMMENDATIONS_ID,
  UNORDERED_RECOMMENDATIONS_ID,
} from 'src/constants/chemical-test';
import sortBy from 'lodash/sortBy';
import each from 'lodash/each';
import { CHEMICAL_DOSAGE_TYPE, OBSERVATION_DOSAGE_TYPE } from 'src/constants/chemical';

export const getRecommendationOrderCards = (
  chemicalTests: ChemicalTest[],
  observationTests: ObservationTest[]
): RecommendationOrderCard[] => {
  const recommendationCards: RecommendationOrderCard[] = [];
  each(chemicalTests, (chemicalTest: ChemicalTest) => {
    const highRecommendationOrderCard = {
      id: `${chemicalTest.id}_high`,
      test: chemicalTest,
      is_high: true,
      name: `${chemicalTest.name} (HIGH)`,
      order: chemicalTest.high_order,
      columnId:
        chemicalTest.high_order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
      type: CHEMICAL_DOSAGE_TYPE,
    } as RecommendationOrderCard;
    const lowRecommendationOrderCard = {
      id: `${chemicalTest.id}_low`,
      test: chemicalTest,
      is_high: false,
      name: `${chemicalTest.name} (LOW)`,
      order: chemicalTest.low_order,
      columnId:
        chemicalTest.low_order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
      type: CHEMICAL_DOSAGE_TYPE,
    } as RecommendationOrderCard;
    recommendationCards.push(highRecommendationOrderCard);
    recommendationCards.push(lowRecommendationOrderCard);
  });

  each(observationTests, (observationTest: ObservationTest) => {
    const orderCard = {
      id: observationTest.id.toString(),
      test: observationTest,
      name: observationTest.name,
      is_high: false,
      order: observationTest.order,
      columnId:
        observationTest.order > 0 ? ORDERED_RECOMMENDATIONS_ID : UNORDERED_RECOMMENDATIONS_ID,
      type: OBSERVATION_DOSAGE_TYPE,
    } as RecommendationOrderCard;
    recommendationCards.push(orderCard);
  });

  return sortBy(recommendationCards, 'order');
};
