import { tokens } from '../tokens';

export const en = {
  [tokens.nav.analytics]: 'Analytics',
  [tokens.nav.archivedQuotes]: 'Archived quotes',
  [tokens.nav.batchBilling]: 'Batch billing',
  [tokens.nav.batchInvoices]: 'Batch invoices',
  [tokens.nav.batchQueue]: 'Batch queue',
  [tokens.nav.brands]: 'Brands',
  [tokens.nav.calendar]: 'Calendar',
  [tokens.nav.campaigns]: 'Campaigns',
  [tokens.nav.colors]: 'Colors',
  [tokens.nav.contacts]: 'Contacts',
  [tokens.nav.contactRelationships]: 'Contact categories',
  [tokens.nav.contactCategories]: 'Contact relationships',
  [tokens.nav.chemicalGroups]: 'Chemical groups',
  [tokens.nav.chemicalTests]: 'Chemical tests',
  [tokens.nav.customExceptions]: 'Custom exceptions',
  [tokens.nav.customFields]: 'Custom fields',
  [tokens.nav.dashboard]: 'Dashboard',
  [tokens.nav.dataCleaning]: 'Data cleaning',
  [tokens.nav.dosageGroups]: 'Dosage groups',
  [tokens.nav.email]: 'Email',
  [tokens.nav.equipment]: 'Equipment',
  [tokens.nav.template]: 'Template',
  [tokens.nav.type]: 'Type',
  [tokens.nav.overview]: 'Overview',
  [tokens.nav.openQuotes]: 'Open quotes',
  [tokens.nav.finance]: 'Finance',
  [tokens.nav.operations]: 'Operations',
  [tokens.nav.observationGroups]: 'Observation groups',
  [tokens.nav.observationTests]: 'Observation tests',
  [tokens.nav.lab]: 'Lab',
  [tokens.nav.jobs]: 'Jobs',
  [tokens.nav.jobTemplates]: 'Job templates',
  [tokens.nav.jobSheet]: 'Job sheet',
  [tokens.nav.jobToInvoice]: 'Job to invoice',
  [tokens.nav.integrations]: 'Integrations',
  [tokens.nav.invoicing]: 'Invoicing',
  [tokens.nav.invoices]: 'Invoices',
  [tokens.nav.invoice]: 'Invoice',
  [tokens.nav.myBusiness]: 'My business',
  [tokens.nav.planAndPayment]: 'Plan & Payment',
  [tokens.nav.users]: 'Users',
  [tokens.nav.quotes]: 'Quotes',
  [tokens.nav.pools]: 'Pools',
  [tokens.nav.products]: 'Products',
  [tokens.nav.recommendationsOrder]: 'Recommendations order',
  [tokens.nav.profile]: 'Profile',
  [tokens.nav.recurrenceDashboard]: 'Recurrence dashboard',
  [tokens.nav.recurrencePeriods]: 'Recurrence periods',
  [tokens.nav.roles]: 'Roles',
  [tokens.nav.sanitisers]: 'Sanitisers',
  [tokens.nav.settings]: 'Settings',
  [tokens.nav.setup]: 'Setup',
  [tokens.nav.sms]: 'SMS',
  [tokens.nav.configure]: 'Configure',
  [tokens.nav.sentMessages]: 'Sent messages',
  [tokens.nav.stripe]: 'Stripe',
  [tokens.nav.surfaces]: 'Surfaces',
  [tokens.nav.tags]: 'Tags',
  [tokens.nav.team]: 'Team',
  [tokens.nav.tradingTerms]: 'Trading terms',
  [tokens.nav.testHistory]: 'Test history',
  [tokens.nav.waterTest]: 'Water test',
  [tokens.nav.waterTesting]: 'Water testing',
  [tokens.nav.xero]: 'Xero',
  [tokens.nav.vend]: 'Vend',

  [tokens.common.account]: 'Account',
  [tokens.common.activeCustomers]: 'Active customers',
  [tokens.common.activePools]: 'Active pools',
  [tokens.common.active]: 'Active',
  [tokens.common.actions]: 'Actions',
  [tokens.common.back]: 'Back',
  [tokens.common.brands]: 'Brands',
  [tokens.common.cancel]: 'Cancel',
  [tokens.common.configure]: 'Configure',
  [tokens.common.copiedToClipboard]: 'Copied to clipboard',
  [tokens.common.contactCategories]: 'Contact categories',
  [tokens.common.contactRelationships]: 'Contact relationships',
  [tokens.common.customExceptions]: 'Custom exceptions',
  [tokens.common.description]: 'Description',
  [tokens.common.edit]: 'Edit',
  [tokens.common.exceptions]: 'Exceptions',
  [tokens.common.languageChanged]: 'Language changed',
  [tokens.common.jobs]: 'Jobs',
  [tokens.common.jobType]: 'Job type',
  [tokens.common.featured]: 'Featured',
  [tokens.common.noFeaturedItems]: 'There are no featured items',
  [tokens.common.noExceptions]: 'There are no exceptions',
  [tokens.common.name]: 'Name',
  [tokens.common.quickLinks]: 'Quick links',
  [tokens.common.waterTests]: 'Water tests',
  [tokens.common.new]: 'New',
  [tokens.common.newContact]: 'New contact',
  [tokens.common.newPool]: 'New pool',
  [tokens.common.newWaterTest]: 'New water test',
  [tokens.common.newJob]: 'New job',
  [tokens.common.newQuote]: 'New quote',
  [tokens.common.newProduct]: 'New product',
  [tokens.common.newPeriod]: 'New period',
  [tokens.common.overview]: 'Overview',
  [tokens.common.period]: 'Period',
  [tokens.common.preview]: 'Preview',
  [tokens.common.update]: 'Update',
  [tokens.common.sanitiser]: 'Sanitiser',
  [tokens.common.sanitisers]: 'Sanitisers',
  [tokens.common.save]: 'Save',
  [tokens.common.surfaces]: 'Surfaces',
  [tokens.common.settings]: 'Settings',
  [tokens.common.startMonth]: 'Start month',
  [tokens.common.testWater]: 'Test water',
  [tokens.common.endMonth]: 'End month',
  [tokens.common.recurrencePeriods]: 'Recurrence periods',
  [tokens.common.resend]: 'Resend',
  [tokens.common.viewPdf]: 'View PDF',

  [tokens.info.customFields]:
    "This section lets you manage custom fields. Don't forget to go to the Water Testing section afterwards and manage the water testing exceptions.",

  [tokens.knowledgeBase.searchTheKnowledgeBase]: 'Search the knowledge base',
  [tokens.knowledgeBase.viewKnowledgeBase]: 'View knowledge base',
  [tokens.knowledgeBase.helpDescription]:
    "Browse the articles and the videos in the Knowledge Base to get answers to your questions or deep-dive into platform to ensure you're getting the most out of it.",
  [tokens.support.raiseTicket]: 'Raise a ticket',
  [tokens.support.contactSupportTeam]: 'Contact support team',
  [tokens.support.helpDescription]:
    "Our support team are on hand to walk you through the product or help you out with a specific question. Log a ticket with details of your issue and we'll respond to you as soon as possible",
};
