import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import { SortDirection } from '@mui/material';
import type {
  ChemicalTest,
  ObservationTest,
  RecommendationOrderCard,
  RecommendationOrderColumn,
} from '../types/chemical';
import objFromArray from 'src/utils/objFromArray';
import {
  ORDERED_RECOMMENDATIONS_ID,
  RECOMMENDATIONS_ORDER_COLUMNS,
  UNORDERED_RECOMMENDATIONS_ID,
} from 'src/constants/chemical-test';
import { getRecommendationOrderCards } from 'src/utils/chemical-test';
import filter from 'lodash/filter';
import map from 'lodash/map';
import { waterTestApi } from 'src/api/water-test';

interface ChemicalTestState {
  searchText?: string;
  orderBy: string;
  order: SortDirection;
  isReodering: boolean;
  orderColumns: {
    byId: Record<string, RecommendationOrderColumn>;
    allIds: string[];
  };
  orderCards: {
    byId: Record<string, RecommendationOrderCard>;
    allIds: string[];
  };
}

const initialState: ChemicalTestState = {
  searchText: '',
  orderBy: 'id',
  order: 'asc',
  isReodering: false,
  orderColumns: {
    byId: {},
    allIds: [],
  },
  orderCards: {
    byId: {},
    allIds: [],
  },
};

const slice = createSlice({
  name: 'chemicalTest',
  initialState,
  reducers: {
    setSearchText(state: ChemicalTestState, action: PayloadAction<{ searchText: string }>) {
      const { searchText } = action.payload;

      state.searchText = searchText;
    },
    setOrder(
      state: ChemicalTestState,
      action: PayloadAction<{ orderBy: string; order: SortDirection }>
    ) {
      const { orderBy, order } = action.payload;

      state.orderBy = orderBy;
      state.order = order;
    },
    getRecommendationOrders(
      state: ChemicalTestState,
      action: PayloadAction<{ chemicalTests: ChemicalTest[]; observationTests: ObservationTest[] }>
    ): void {
      const { chemicalTests, observationTests } = action.payload;
      state.orderColumns = {
        ...initialState.orderColumns,
      };
      state.orderCards = {
        ...initialState.orderCards,
      };
      let columns = RECOMMENDATIONS_ORDER_COLUMNS;
      const cards = getRecommendationOrderCards(chemicalTests, observationTests);
      columns = map(columns, (_column) => {
        const column = {
          ..._column,
        };
        if (column.id === ORDERED_RECOMMENDATIONS_ID) {
          const orderedTests = filter(
            cards,
            (card) => card.columnId === ORDERED_RECOMMENDATIONS_ID
          );
          column.cardIds = map(orderedTests, (recommendationCard) => recommendationCard.id);
        } else {
          const unOrderedTests = filter(
            cards,
            (card) => card.columnId === UNORDERED_RECOMMENDATIONS_ID
          );
          column.cardIds = map(unOrderedTests, (recommendationCard) => recommendationCard.id);
        }
        return column;
      });

      state.orderColumns.byId = objFromArray(columns);
      state.orderColumns.allIds = Object.keys(state.orderColumns.byId);
      state.orderCards.byId = objFromArray(cards);
      state.orderCards.allIds = Object.keys(state.orderCards.byId);
    },
    moveRecommendationOrder(
      state: ChemicalTestState,
      action: PayloadAction<{ cardId: string; position: number; columnId?: string }>
    ): void {
      const { cardId, position, columnId } = action.payload;
      const sourceColumnId = state.orderCards.byId[cardId].columnId;

      // Remove card from source column
      state.orderColumns.byId[sourceColumnId].cardIds = state.orderColumns.byId[
        sourceColumnId
      ].cardIds.filter((_cardId) => _cardId !== cardId);

      // If columnId exists, it means that we have to add the card to the new column
      if (columnId) {
        // Change card's columnId reference
        state.orderCards.byId[cardId].columnId = columnId;
        // Push the cardId to the specified position
        state.orderColumns.byId[columnId].cardIds.splice(position, 0, cardId);
      } else {
        // Push the cardId to the specified position
        state.orderColumns.byId[sourceColumnId].cardIds.splice(position, 0, cardId);
      }
      state.isReodering = true;
    },
  },
  extraReducers: (builder) => {
    builder
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchPending, (state, action) => {
        console.log('pending', action);
      })
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchFulfilled, (state, action) => {
        state.isReodering = false;
      })
      .addMatcher(waterTestApi.endpoints.updateTestsOrder.matchRejected, (state, action) => {
        state.isReodering = false;
      });
  },
});

export const { reducer } = slice;

export const { getRecommendationOrders, moveRecommendationOrder } = slice.actions;

export const setSearchText = (searchText: string) => (dispatch) => {
  dispatch(slice.actions.setSearchText({ searchText }));
};

export const setOrder = (orderBy: string, order: SortDirection) => (dispatch) => {
  dispatch(slice.actions.setOrder({ orderBy, order }));
};

export default slice;
