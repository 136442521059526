export const tokens = {
  common: {
    active: 'common.active',
    actions: 'common.actions',
    account: 'common.account',
    activeCustomers: 'common.activeCustomers',
    activePools: 'common.activePools',
    back: 'common.back',
    brands: 'common.brands',
    cancel: 'common.cancel',
    configure: 'common.configure',
    copiedToClipboard: 'common.copiedToClipboard',
    contactCategories: 'common.contactCategories',
    contactRelationships: 'common.contactRelationships',
    customExceptions: 'common.customExceptions',
    description: 'common.description',
    edit: 'common.edit',
    exceptions: 'common.exceptions',
    languageChanged: 'common.languageChanged',
    jobs: 'common.jobs',
    jobType: 'common.jobType',
    featured: 'common.featured',
    noFeaturedItems: 'common.noFeaturedItems',
    noExceptions: 'common.noExceptions',
    quickLinks: 'common.quickLinks',
    waterTests: 'common.waterTests',
    name: 'common.name',
    newWaterTest: 'common.newWaterTest',
    new: 'common.new',
    newJob: 'common.newJob',
    newQuote: 'common.newQuote',
    newContact: 'common.newContact',
    newPeriod: 'common.newPeriod',
    newPool: 'common.newPool',
    newProduct: 'common.newProduct',
    overview: 'common.overview',
    recurrencePeriods: 'common.recurrencePeriods',
    resend: 'common.resend',
    viewPdf: 'common.viewPdf',
    period: 'common.period',
    preview: 'common.preview',
    update: 'common.update',
    sanitiser: 'common.sanitiser',
    sanitisers: 'common.sanitisers',
    save: 'common.save',
    surfaces: 'common.surfaces',
    settings: 'common.settings',
    startMonth: 'common.startMonth',
    testWater: 'common.testWater',
    endMonth: 'common.endMonth',
  },
  info: {
    customFields: 'info.customFields',
  },
  knowledgeBase: {
    searchTheKnowledgeBase: 'knowledgeBase.searchTheKnowledgeBase',
    helpDescription: 'knowledgeBase.helpDescription',
    viewKnowledgeBase: 'knowledgeBase.viewKnowledgeBase',
  },
  support: {
    raiseTicket: 'support.raiseTicket',
    helpDescription: 'support.helpDescription',
    contactSupportTeam: 'support.contactSupportTeam',
  },
  nav: {
    analytics: 'nav.analytics',
    archivedQuotes: 'nav.archivedQuotes',
    batchBilling: 'nav.batchBilling',
    batchInvoices: 'nav.batchInvoices',
    batchQueue: 'nav.batchQueue',
    brands: 'nav.brands',
    calendar: 'nav.calendar',
    campaigns: 'nav.campaigns',
    colors: 'nav.colors',
    contacts: 'nav.contacts',
    contactCategories: 'nav.contactCategories',
    contactRelationships: 'nav.contactRelationships',
    chemicalGroups: 'nav.chemicalGroups',
    chemicalTests: 'nav.chemicalTests',
    customFields: 'nav.customFields',
    customExceptions: 'nav.customExceptions',
    dashboard: 'nav.dashboard',
    dataCleaning: 'nav.dataCleaning',
    dosageGroups: 'nav.dosageGroups',
    equipment: 'nav.equipment',
    template: 'nav.template',
    type: 'nav.type',
    finance: 'nav.finance',
    overview: 'nav.overview',
    jobs: 'nav.jobs',
    jobTemplates: 'nav.jobTemplates',
    jobSheet: 'nav.jobSheet',
    jobToInvoice: 'nav.jobToInvoice',
    lab: 'nav.lab',
    integrations: 'nav.integrations',
    invoicing: 'nav.invoicing',
    invoice: 'nav.invoice',
    invoices: 'nav.invoices',
    myBusiness: 'nav.myBusiness',
    operations: 'nav.operations',
    openQuotes: 'nav.openQuotes',
    observationGroups: 'nav.observationGroups',
    observationTests: 'nav.observationTests',
    planAndPayment: 'nav.planAndPayment',
    team: 'nav.team',
    tradingTerms: 'nav.tradingTerms',
    users: 'nav.users',
    profile: 'nav.profile',
    quotes: 'nav.quotes',
    email: 'nav.email',
    settings: 'nav.settings',
    pools: 'nav.pools',
    products: 'nav.products',
    recommendationsOrder: 'nav.recommendationsOrder',
    recurrenceDashboard: 'nav.recurrenceDashboard',
    recurrencePeriods: 'nav.recurrencePeriods',
    roles: 'nav.roles',
    sanitisers: 'nav.sanitisers',
    setup: 'nav.setup',
    sms: 'nav.sms',
    configure: 'nav.configure',
    sentMessages: 'nav.sentMessages',
    surfaces: 'nav.surfaces',
    stripe: 'nav.stripe',
    tags: 'nav.tags',
    testHistory: 'nav.testHistory',
    waterTest: 'nav.waterTest',
    waterTesting: 'nav.waterTesting',
    xero: 'nav.xero',
    verifyCode: 'nav.verifyCode',
    vend: 'nav.vend',
  },
};
