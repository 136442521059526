import { FC, useEffect, useState } from 'react';
import { useCallback } from 'react';
import PropTypes from 'prop-types';
import XIcon from '@untitled-ui/icons-react/build/esm/X';
import { Drawer, IconButton, Stack, SvgIcon, Typography } from '@mui/material';
import type { ThemeSettings as Settings } from '../../types/settings';
import { Scrollbar } from '../scrollbar';
import { OptionsContrast } from './options-contrast';
import { OptionsLayout } from './options-layout';
import { OptionsNavColor } from './options-nav-color';
import { OptionsColorScheme } from './options-color-scheme';
import { OptionsStretch } from './options-stretch';
import { useSettings } from 'src/hooks/use-settings';
import { OptionsEnvironment } from './options-environment';
import { useSelector } from 'src/store';
import { selectEnvironment } from 'src/slices/settings';

interface SettingsDrawerProps {
  onClose?: () => void;
  onUpdate?: (settings: Settings) => void;
  open?: boolean;
  values?: Settings;
}

const getValues = (settings: Settings) => ({
  contrast: settings.contrast,
  direction: settings.direction,
  paletteMode: settings.paletteMode,
  responsiveFontSizes: settings.responsiveFontSizes,
  compact: settings.compact,
  layout: settings.layout,
  navColor: settings.navColor,
});

export const SettingsDrawer: FC<SettingsDrawerProps> = (props) => {
  const { onClose, onUpdate, open, ...other } = props;
  const { settings, saveSettings, saveEnvironment } = useSettings();
  const [values, setValues] = useState(getValues(settings));
  const environment = useSelector(selectEnvironment);
  const [currentEnvironment, setCurrentEnvironment] = useState(environment);

  useEffect(() => {
    setValues(getValues(settings));
  }, [settings]);

  const handleFieldUpdate = useCallback(
    (field: keyof Settings, value: unknown): void => {
      setValues({
        ...values,
        [field]: value,
      });
      saveSettings({
        ...values,
        [field]: value,
      });
    },
    [setValues, saveSettings]
  );

  const handleChangeEnvironment = useCallback(
    (value: string): void => {
      setCurrentEnvironment(value);
      saveEnvironment(value);
    },
    [setCurrentEnvironment, saveEnvironment]
  );

  return (
    <Drawer
      disableScrollLock
      anchor="right"
      onClose={onClose}
      open={open}
      ModalProps={{
        BackdropProps: {
          invisible: true,
        },
        sx: { zIndex: 1400 },
      }}
      PaperProps={{
        elevation: 24,
        sx: {
          maxWidth: '100%',
          width: 440,
        },
      }}
      {...other}
    >
      <Scrollbar
        sx={{
          height: '100%',
          '& .simplebar-content': {
            height: '100%',
          },
          '& .simplebar-scrollbar:before': {
            background: 'var(--nav-scrollbar-color)',
          },
        }}
      >
        <Stack
          alignItems="center"
          direction="row"
          justifyContent="space-between"
          spacing={3}
          sx={{
            px: 3,
            pt: 2,
          }}
        >
          <Typography variant="h6">App Settings</Typography>
          <IconButton
            color="inherit"
            onClick={onClose}
          >
            <SvgIcon>
              <XIcon />
            </SvgIcon>
          </IconButton>
        </Stack>
        <Stack
          spacing={5}
          sx={{ p: 2 }}
        >
          <OptionsColorScheme
            onChange={(value) => handleFieldUpdate('paletteMode', value)}
            value={values.paletteMode}
          />
          <OptionsNavColor
            onChange={(value) => handleFieldUpdate('navColor', value)}
            value={values.navColor}
          />
          <OptionsLayout
            onChange={(value) => handleFieldUpdate('layout', value)}
            value={values.layout}
          />
          <OptionsStretch
            onChange={(value) => handleFieldUpdate('compact', value)}
            value={values.compact}
          />
          <OptionsContrast
            onChange={(value) => handleFieldUpdate('contrast', value)}
            value={values.contrast}
          />
          <OptionsEnvironment
            onChange={(value) => handleChangeEnvironment(value)}
            value={currentEnvironment}
          />
        </Stack>
      </Scrollbar>
    </Drawer>
  );
};

SettingsDrawer.propTypes = {
  onClose: PropTypes.func,
  onUpdate: PropTypes.func,
  open: PropTypes.bool,
  // @ts-ignore
  values: PropTypes.object,
};
