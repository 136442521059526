import axios from 'axios';
import { createAsyncThunk } from '@reduxjs/toolkit';
import { ChemicalResult, ObservationResult } from '../types/water-test';
import filter from 'lodash/filter';
import { analytics } from 'src/lib/firebase';
import { logEvent } from 'firebase/analytics';
import { format } from 'date-fns';

interface GenerateReportBody {
  id: number;
  nextTestDate: Date | null;
  chemicalResults: ChemicalResult[];
  observationResults: ObservationResult[];
  reportData: any;
}

const generateWaterTestReport = createAsyncThunk<void, GenerateReportBody>(
  'water-test-report/generate',
  async (
    { id, nextTestDate, chemicalResults, observationResults, reportData },
    { getState, rejectWithValue }
  ) => {
    // @ts-ignore
    const { organisation } = getState().account;
    const config = { headers: { 'Content-Type': 'multipart/form-data' } };
    const fd = new FormData();

    try {
      const extension = reportData.type.split('/')[1];
      fd.append('file', reportData, `filename.${extension}`);
      await axios.post(`v2/organisations/${organisation.id}/water-test/${id}/report`, fd, config);
      const activeChemicalResults = filter(
        chemicalResults,
        (chemicalResult) => chemicalResult.value !== ''
      );
      const activeObservationResults = filter(
        observationResults,
        (observationResult) => observationResult.value
      );
      const resultsData = {
        next_test_date: nextTestDate ? format(nextTestDate, 'yyyy-MM-dd') : null,
        chemical_results: activeChemicalResults.map((chemicalResult: ChemicalResult) => ({
          value: chemicalResult.value,
          name: chemicalResult.name,
          action: chemicalResult.action || '',
          status: chemicalResult.status,
          min_value: chemicalResult.min_value,
          max_value: chemicalResult.max_value,
          target_value: chemicalResult.target_value,
          description: chemicalResult.description || '',
          short_description: chemicalResult.short_description || '',
          show_on_report: chemicalResult.show_on_report,
          chemical_test_id: chemicalResult.chemical_test_id,
          chemical_group_id: chemicalResult.chemical_group_id || null,
        })),
        observation_results: activeObservationResults.map(
          (observationResult: ObservationResult) => ({
            value: observationResult.value,
            name: observationResult.name,
            action: observationResult.action || '',
            description: observationResult.description || '',
            short_description: observationResult.short_description || '',
            show_on_report: observationResult.show_on_report,
            observation_test_id: observationResult.observation_test_id,
            observation_group_id: observationResult.observation_group_id || null,
          })
        ),
      };

      logEvent(analytics(), 'generate_report', {
        type: 'water_test_report',
        test_results: activeChemicalResults.length,
        observation_results: activeObservationResults.length,
      });

      return axios.post(
        `v2/organisations/${organisation.id}/water-test/${id}/results`,
        resultsData
      );
    } catch (e) {
      return rejectWithValue({
        error: JSON.stringify(e.response?.data),
      });
    }
  }
);

export const thunks = {
  generateWaterTestReport,
};
