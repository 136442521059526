import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import axios from 'axios';
import * as Yup from 'yup';

import { AppThunk } from 'src/store';
import { Address } from 'src/types/address';
import { Contact, Phone } from 'src/types/contact';
import { uniquePhones } from 'src/utils/contact';
import {
  CommsPrefType,
  ContactType,
  DuplicationSwitcher,
} from 'src/types/deduplicate-contact-tool';

interface DeduplicateContactToolState {
  isLoadingDuplications: boolean;
  isMerging: boolean;

  searchQuery: string;
  switchers: DuplicationSwitcher[];

  duplicates: { [group: string]: GroupDuplications };
  duplicatesCount: number;

  pageLimit: number;
  currentPage: number;
  expandedGroupKey: string;
}

interface GroupDuplications {
  primaryContactDuplicationId: number;
  groupName: string;
  contactDuplications: ContactDuplication[];
}

interface ContactDuplication {
  // information about contact
  contact: Contact;

  // current selected values of field
  type: ContactType;
  companyName: string;
  firstName: string;
  lastName: string;
  phones: Phone[];
  email: string;
  ccEmails: string[];
  addressIndex: number;
  contactName: string;
  commsPref: CommsPrefType;
  active: boolean;

  // available values
  firstNames: string[];
  lastNames: string[];
  allPhones: Phone[];
  emails: string[];
  addresses: Address[];
  contactNames: string[];
}

const initialState: DeduplicateContactToolState = {
  isLoadingDuplications: false,
  isMerging: false,

  switchers: [DuplicationSwitcher.email],
  searchQuery: '',

  duplicates: {},
  duplicatesCount: 0,

  pageLimit: 10,
  currentPage: 0,
  expandedGroupKey: null,
};

const emailSchema = Yup.string().email();

function getContactDuplicationIndexByGroup(
  state: DeduplicateContactToolState,
  groupKey: string,
  contactId: number
): number {
  const duplicationGroup = state.duplicates[groupKey];

  if (duplicationGroup == null) {
    return -1;
  }

  const contactDuplicationIndex = duplicationGroup.contactDuplications.findIndex(
    (contactDuplication) => contactDuplication.contact.id === contactId
  );
  return contactDuplicationIndex;
}

const slice = createSlice({
  name: 'deduplicateContactTool',
  initialState,
  reducers: {
    startLoadingDuplications(state: DeduplicateContactToolState) {
      state.isLoadingDuplications = true;
    },
    stopLoadingDuplications(state: DeduplicateContactToolState) {
      state.isLoadingDuplications = false;
    },
    startMergingDuplications(state: DeduplicateContactToolState) {
      state.isLoadingDuplications = true;
    },
    stopMergingDuplications(state: DeduplicateContactToolState) {
      state.isLoadingDuplications = false;
    },
    setSearchQuery(
      state: DeduplicateContactToolState,
      action: PayloadAction<{ searchQuery: string }>
    ) {
      state.searchQuery = action.payload.searchQuery;
    },
    setPageLimit(state: DeduplicateContactToolState, action: PayloadAction<{ limit: number }>) {
      state.pageLimit = action.payload.limit;
      state.currentPage = initialState.currentPage;
    },
    setDuplications(
      state: DeduplicateContactToolState,
      action: PayloadAction<{ duplications: { [group: string]: Contact[] } }>
    ) {
      const { duplications } = action.payload;
      const duplicatesForState: { [group: string]: GroupDuplications } = {};

      Object.entries(duplications).forEach(([group, contacts]) => {
        const firstNames = Array.from(new Set(contacts.map(({ first_name }) => first_name)));
        const lastNames = Array.from(new Set(contacts.map(({ last_name }) => last_name)));
        const allPhones = uniquePhones(contacts.map(({ phones }) => phones).flat());

        const emails = Array.from(new Set(contacts.map(({ email }) => email))).filter(
          (e) => e.length !== 0
        );
        const addresses: Address[] = contacts.map((contact) => ({
          address_street_one: contact.address_street_one,
          address_street_two: contact.address_street_two,
          address_postcode: contact.address_postcode,
          address_state: contact.address_state,
          address_city: contact.address_city,
          address_country: contact.address_country,
        }));
        const contactNames = Array.from(new Set(contacts.map(({ contact_name }) => contact_name)));

        duplicatesForState[group] = {
          primaryContactDuplicationId: contacts[0].id,
          groupName: group,
          contactDuplications: contacts.map((contact) => {
            const addressIndex = addresses.findIndex(
              (address) =>
                address.address_country === contact.address_country &&
                address.address_postcode === contact.address_postcode &&
                address.address_state === contact.address_state &&
                address.address_street_one === contact.address_street_one
            );
            return {
              contact,

              type: ContactType.individual,
              companyName: contact.company_name,
              firstName: contact.first_name,
              lastName: contact.last_name,
              phones: uniquePhones(contact.phones),
              email: contact.email,
              ccEmails: (contact.cc_emails || '')
                .split(',')
                .map((e) => e.trim())
                .filter((e) => e.length !== 0),
              addressIndex,
              contactName: contact.contact_name || '',
              commsPref: contact.invoice_pref,
              active: contact.visibility || false,

              firstNames,
              lastNames,
              allPhones,
              emails,
              addresses,
              contactNames,
            };
          }),
        };
      });
      state.duplicates = duplicatesForState;
      state.duplicatesCount = Object.keys(state.duplicates).length;
      state.currentPage = initialState.currentPage;
      state.expandedGroupKey = initialState.expandedGroupKey;
    },
    updateField(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
        fieldName: string;
        value: string | number | boolean | ContactType | Phone[] | Address;
      }>
    ) {
      const { groupKey, contactId, fieldName, value } = action.payload;
      const contactDuplicationIndex = getContactDuplicationIndexByGroup(state, groupKey, contactId);

      if (contactDuplicationIndex === -1) {
        return;
      }

      state.duplicates[groupKey].contactDuplications[contactDuplicationIndex][fieldName] = value;
    },
    addCustomEmail(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
        email: string;
      }>
    ) {
      const { groupKey, contactId } = action.payload;
      const email = action.payload.email.trim();

      if (!emailSchema.isValidSync(email)) {
        return;
      }

      const contactDuplicationIndex = getContactDuplicationIndexByGroup(state, groupKey, contactId);

      if (contactDuplicationIndex === -1) {
        return;
      }

      const contactDuplication =
        state.duplicates[groupKey].contactDuplications[contactDuplicationIndex];

      if (!contactDuplication.emails.includes(email)) {
        contactDuplication.emails = [...contactDuplication.emails, email];
      }

      contactDuplication.email = email;
    },
    addCCEmail(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
        email: string;
      }>
    ) {
      const { groupKey, contactId, email } = action.payload;

      if (email.trim().length === 0) {
        return;
      }

      if (!emailSchema.isValidSync(email)) {
        return;
      }

      const contactDuplicationIndex = getContactDuplicationIndexByGroup(state, groupKey, contactId);

      if (contactDuplicationIndex === -1) {
        return;
      }

      const contactDuplication =
        state.duplicates[groupKey].contactDuplications[contactDuplicationIndex];
      contactDuplication.ccEmails = Array.from(new Set([...contactDuplication.ccEmails, email]));
    },
    removeCCEmail(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
        email: string;
      }>
    ) {
      const { groupKey, contactId, email } = action.payload;
      const contactDuplicationIndex = getContactDuplicationIndexByGroup(state, groupKey, contactId);

      if (contactDuplicationIndex === -1) {
        return;
      }

      const contactDuplication =
        state.duplicates[groupKey].contactDuplications[contactDuplicationIndex];
      contactDuplication.ccEmails = contactDuplication.ccEmails.filter(
        (_email) => _email !== email
      );
    },
    ignoreContact(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
      }>
    ) {
      const { groupKey, contactId } = action.payload;
      const duplicationGroup = state.duplicates[groupKey];

      if (duplicationGroup == null || duplicationGroup.contactDuplications.length === 1) {
        return;
      }

      duplicationGroup.contactDuplications = duplicationGroup.contactDuplications.filter(
        (contactDuplication) => contactDuplication.contact.id !== contactId
      );

      if (duplicationGroup.contactDuplications.length === 1) {
        duplicationGroup.primaryContactDuplicationId =
          duplicationGroup.contactDuplications[0].contact?.id;
      }
    },
    ignoreDuplicationGroup(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
      }>
    ) {
      const { groupKey } = action.payload;
      delete state.duplicates[groupKey];
      state.duplicatesCount -= 1;
    },
    makePrimary(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
        contactId: number;
      }>
    ) {
      const { groupKey, contactId } = action.payload;
      const duplicationGroup = state.duplicates[groupKey];

      if (duplicationGroup == null) {
        return;
      }

      duplicationGroup.primaryContactDuplicationId = contactId;
    },
    updateDuplicationSwitcher(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        switcher: DuplicationSwitcher;
        checked: boolean;
      }>
    ) {
      const { switcher, checked } = action.payload;
      if (checked) {
        state.switchers = [...state.switchers, switcher];
      } else if (state.switchers.length > 1) {
        state.switchers = state.switchers.filter((s) => s !== switcher);
      }
    },
    setPage(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        page: number;
      }>
    ) {
      state.currentPage = action.payload.page;
    },
    setExpandedGroupKey(
      state: DeduplicateContactToolState,
      action: PayloadAction<{
        groupKey: string;
      }>
    ) {
      state.expandedGroupKey = action.payload.groupKey;
    },
  },
});

export const { reducer } = slice;
export const {
  setPage,
  setPageLimit,
  setSearchQuery,
  updateDuplicationSwitcher,
  updateField,
  addCCEmail,
  removeCCEmail,
  addCustomEmail,
  ignoreContact,
  makePrimary,
  ignoreDuplicationGroup,
  setExpandedGroupKey,
} = slice.actions;

export const getDuplications =
  (organisationId: number, duplicationTypes: DuplicationSwitcher[]): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingDuplications());
    dispatch(slice.actions.setDuplications({ duplications: {} }));
    const filterBy = duplicationTypes
      .map((duplicationType) => duplicationType.toString())
      .join(',');

    try {
      const response = await axios.get(
        `v1/organisations/${organisationId}/get-duplicated-contacts`,
        { params: { filterBy } }
      );
      dispatch(
        slice.actions.setDuplications({
          duplications: response.data.duplicates,
        })
      );
    } finally {
      dispatch(slice.actions.stopLoadingDuplications());
    }
  };

export const mergeContacts =
  (
    organisationId: number,
    groupDuplication: GroupDuplications,
    duplicationTypes: DuplicationSwitcher[]
  ): AppThunk =>
  async (dispatch) => {
    dispatch(slice.actions.startLoadingDuplications());

    const { primaryContactDuplicationId } = groupDuplication;
    const primaryDuplication = groupDuplication.contactDuplications.find(
      (duplication) => primaryContactDuplicationId === duplication.contact.id
    );
    const deduplicateContactIds = groupDuplication.contactDuplications
      .filter((contactDuplication) => contactDuplication.contact.id !== primaryContactDuplicationId)
      .map(({ contact }) => ({ id: contact.id }));
    const address = primaryDuplication.addresses[primaryDuplication.addressIndex];
    const payload = {
      id: primaryDuplication.contact.id,
      address_city: address.address_city,
      address_country: address.address_country,
      address_postcode: address.address_postcode,
      address_state: address.address_state,
      address_street_one: address.address_street_one,
      address_street_two: address.address_street_two,
      bgw_comms_pref: primaryDuplication.contact.bgw_comms_pref,
      created_at: primaryDuplication.contact.created_at,
      full_address: primaryDuplication.contact.full_address,
      is_batch: primaryDuplication.contact.is_batch,
      is_vend_contact_deleted: primaryDuplication.contact.is_vend_contact_deleted,
      notes: primaryDuplication.contact.notes,
      organisation_id: primaryDuplication.contact.organisation_id,
      trading_term_id: primaryDuplication.contact.trading_term_id,
      updated_at: primaryDuplication.contact.updated_at,
      pools: primaryDuplication.contact.pools.map((pool) => ({
        pool_id: pool.id,
        address_type_id: pool.pool_type_id,
      })),

      cc_emails: primaryDuplication.ccEmails.join(','),
      company_name: primaryDuplication.companyName,
      contact_name: primaryDuplication.contactName,
      email: primaryDuplication.email,
      first_name: primaryDuplication.firstName,
      last_name: primaryDuplication.lastName,
      phones: primaryDuplication.phones,
      type: primaryDuplication.type,
      full_name: `${primaryDuplication.firstName} ${primaryDuplication.lastName}`,
      invoice_pref: primaryDuplication.commsPref,
      visibility: primaryDuplication.active,
      deduplicate_contacts: deduplicateContactIds,
    };

    try {
      await axios.put(
        `v1/organisations/${organisationId}/contacts/${primaryDuplication.contact.id}`,
        payload
      );
      dispatch(getDuplications(organisationId, duplicationTypes));
    } finally {
      dispatch(slice.actions.stopLoadingDuplications());
    }
  };
