import { createSlice, createAsyncThunk } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { IStatusImage } from 'src/types/image-uploader';
import {
  IGetImagesTokenResponse,
  IGetImagesToken,
  IGetImagesTokenError,
} from 'src/types/image-uploader';
import axios from 'axios';
import { t } from 'i18next';
import { FAILED, PENDING, SUCCEEDED } from 'src/constants/api';
import { IEntityImage } from 'src/api/model/store-images.model';
import find from 'lodash/find';
import map from 'lodash/map';
import { FileRejection } from 'react-dropzone';

export interface StoredImage {
  image: IEntityImage;
  isSelected: boolean;
}

export interface TemporaryImage {
  image: IStatusImage;
  isSelected: boolean;
}

export interface ErrorMessage {
  code: string;
  message: string;
}

export interface EntityImages {
  stored: StoredImage[];
  temporary: TemporaryImage[];
  errors: FileRejection[];
}

interface IImageUploaderState {
  images: {
    byEntity: Record<string, EntityImages>;
  };
}

interface IStoreViaSignedUrl {
  file: File;
  entityType: string;
  entityId: string;
  id: string;
}

const initialState: IImageUploaderState = {
  images: {
    byEntity: {},
  },
};

export const storeImageViaSignedUrl = createAsyncThunk<
  any,
  IStoreViaSignedUrl,
  IGetImagesTokenError
>(
  'image-uploader/store-viewsigned-url',
  async ({ id, file, entityType, entityId }, { getState, rejectWithValue, fulfillWithValue }) => {
    try {
      // @ts-ignore
      const { organisation } = getState().account;
      const filename = file.name;
      const fileExtension = filename.split('.').pop().toLowerCase();
      const { data } = await axios.post<IGetImagesToken, { data: IGetImagesTokenResponse }>(
        `v2/organisation/${organisation.id}/image_tokens`,
        {
          entity_type: entityType,
          file_extension: fileExtension,
          filename,
        }
      );
      await fetch(data.pre_signed_url, { method: 'PUT', body: file });
      return fulfillWithValue({
        result: {
          id,
          entityId,
          signedUrl: data.pre_signed_url,
          url: data.url,
          filename,
        },
      });
    } catch (e) {
      return rejectWithValue({
        messages: axios.isAxiosError(e)
          ? Object.values(e.response.data as { [key: string]: string }).reduce(
              (accArray, item) => [...accArray, ...item],
              []
            )
          : [t('Something went wrong, try again')],
      });
    }
  }
);

const slice = createSlice({
  name: 'imageUploader',
  initialState,
  reducers: {
    reset: () => initialState,
    init(
      state: IImageUploaderState,
      action: PayloadAction<{ entityId: string; images: IEntityImage[] }>
    ): void {
      const { entityId, images } = action.payload;

      state.images.byEntity[entityId] = {
        stored: map(
          images,
          (_image): StoredImage =>
            ({
              image: { ..._image },
              isSelected: true,
            } as StoredImage)
        ),
        temporary: [],
        errors: [],
      };
    },
    toggleImage(
      state: IImageUploaderState,
      action: PayloadAction<{ entityId: string; image: IEntityImage | IStatusImage }>
    ): void {
      const { entityId, image } = action.payload;
      const currentState = state.images.byEntity[entityId];
      state.images.byEntity[entityId] = {
        ...currentState,
        stored: currentState.stored.map((storedImage): StoredImage => {
          const { image: _image, isSelected } = storedImage;
          if (_image.id === image.id) {
            return {
              image: {
                ..._image,
              },
              isSelected: !isSelected,
            };
          }
          return storedImage;
        }),
        temporary: currentState.temporary.map((temporaryImage): TemporaryImage => {
          const { image: _image, isSelected } = temporaryImage;
          if (_image.id === image.id) {
            return {
              image: {
                ..._image,
              },
              isSelected: !isSelected,
            };
          }
          return temporaryImage;
        }),
      };
    },
    applyChanges(
      state: IImageUploaderState,
      action: PayloadAction<{ entityId: string; items: StoredImage[] | TemporaryImage[] }>
    ): void {
      const { entityId, items } = action.payload;
      const currentState = state.images.byEntity[entityId];
      state.images.byEntity[entityId] = {
        ...currentState,
        stored: currentState.stored.map((storedImage): StoredImage => {
          const { image: _image } = storedImage;
          return {
            image: {
              ..._image,
            },
            isSelected: find(
              items,
              (mixedItem: StoredImage | TemporaryImage) => mixedItem.image.id === _image.id
            )?.isSelected,
          };
        }),
        temporary: currentState.temporary.map((temporaryImage): TemporaryImage => {
          const { image: _image } = temporaryImage;
          return {
            image: {
              ..._image,
            },
            isSelected: find(
              items,
              (mixedItem: StoredImage | TemporaryImage) => mixedItem.image.id === _image.id
            )?.isSelected,
          };
        }),
      };
    },
    setErrors(
      state: IImageUploaderState,
      action: PayloadAction<{ entityId: string; errors: FileRejection[] }>
    ): void {
      const { entityId, errors } = action.payload;
      const currentState = state.images.byEntity[entityId];
      state.images.byEntity[entityId] = {
        ...currentState,
        errors,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(storeImageViaSignedUrl.pending, (state, action) => {
      const { arg } = action.meta;
      const uploadingImage: IStatusImage = {
        id: arg.id,
        file: arg.file,
        status: PENDING,
        filename: arg.file.name,
        isTemporary: true,
        url: '',
      };
      const currentState = state.images.byEntity[arg.entityId];
      state.images.byEntity[arg.entityId] = {
        ...currentState,
        temporary: [
          ...currentState.temporary,
          {
            image: uploadingImage,
            isSelected: false,
          },
        ],
      };
    });
    builder.addCase(storeImageViaSignedUrl.fulfilled, (state, action) => {
      const { result } = action.payload;
      const currentState = state.images.byEntity[result.entityId];
      state.images.byEntity[result.entityId] = {
        ...currentState,
        temporary: currentState.temporary.map((temporaryImage): TemporaryImage => {
          const { image: _image } = temporaryImage;

          if (_image.status === PENDING && _image.id === result.id) {
            return {
              image: {
                ..._image,
                status: SUCCEEDED,
                url: result.url,
              },
              isSelected: true,
            };
          }
          return temporaryImage;
        }),
      };
    });
    builder.addCase(storeImageViaSignedUrl.rejected, (state, action) => {
      const { arg } = action.meta;
      const currentState = state.images.byEntity[arg.entityId];
      state.images.byEntity[arg.entityId] = {
        ...currentState,
        temporary: currentState.temporary.map((temporaryImage): TemporaryImage => {
          const { image: _image } = temporaryImage;

          return {
            ...temporaryImage,
            image: {
              ..._image,
              status: _image.id === arg.id ? FAILED : _image.status,
            },
          };
        }),
      };
    });
  },
});

export const { reducer } = slice;
export const { init, toggleImage, applyChanges, setErrors } = slice.actions;

export default slice;
