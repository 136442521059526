import type { FC } from 'react';
import PropTypes from 'prop-types';
import { Stack, TextField, Typography } from '@mui/material';
import { selectEnvironmentConfigs } from 'src/slices/settings';
import { useSelector } from 'src/store';

interface OptionsEnvironmentProps {
  onChange?: (value: string) => void;
  value?: string;
}

export const OptionsEnvironment: FC<OptionsEnvironmentProps> = (props) => {
  const { onChange, value } = props;
  const environmentConfigs = useSelector(selectEnvironmentConfigs);

  return (
    <Stack spacing={1}>
      <Typography
        color="text.secondary"
        variant="overline"
      >
        Environment
      </Typography>
      <TextField
        fullWidth
        name="environment"
        onChange={(event) => onChange?.(event.target.value)}
        select
        size="small"
        InputLabelProps={{ shrink: true }}
        SelectProps={{ native: true }}
        value={value}
        variant="outlined"
      >
        {environmentConfigs.allIds.map((environmentId: string) => (
          <option
            key={environmentId}
            value={environmentId}
          >
            {environmentId}
          </option>
        ))}
      </TextField>
    </Stack>
  );
};

OptionsEnvironment.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string,
};
