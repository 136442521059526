import { createSlice } from '@reduxjs/toolkit';
import type { PayloadAction } from '@reduxjs/toolkit';
import type { AppThunk } from 'src/store';
import type { LabJob } from '../types/labJob';
import { SPIN_READER } from 'src/constants/water-test';
import { labJobApi } from 'src/api/labjob';
import { environmentConfig } from 'src/config';
import { thunks } from 'src/thunks/water-test';

export interface ReportConfig {
  layout: string;
  showCampaign: boolean;
  showLogo: boolean;
}

interface WaterTestState {
  isLoading: boolean;
  labJob?: LabJob;
  isPreviewPdfOpen: boolean;
  reportConfig: ReportConfig;
  generatingReport: 'idle' | 'pending' | 'succeeded' | 'failed';
  errorReport: null | string;
  connectedDevice: string;
}

const initialState: WaterTestState = {
  isLoading: false,
  labJob: null,
  reportConfig: {
    layout: environmentConfig.defaultReport,
    showLogo: true,
    showCampaign: false,
  },
  isPreviewPdfOpen: false,
  generatingReport: 'idle',
  errorReport: null,
  connectedDevice: SPIN_READER,
};

const slice = createSlice({
  name: 'waterTest',
  initialState,
  reducers: {
    reset(state: WaterTestState): void {
      state = { ...initialState, connectedDevice: state.connectedDevice };
    },
    openPreviewPdf(state: WaterTestState): void {
      state.isPreviewPdfOpen = true;
    },
    closePreviewPdf(state: WaterTestState): void {
      state.isPreviewPdfOpen = false;
    },
    changeLayout(state: WaterTestState, action: PayloadAction<{ layout: string }>): void {
      const { layout } = action.payload;
      state.reportConfig = {
        ...state.reportConfig,
        layout,
      };
    },
    changeDevice(state: WaterTestState, action: PayloadAction<{ device: string }>): void {
      const { device } = action.payload;
      state.connectedDevice = device;
    },
    applyReportConfig(
      state: WaterTestState,
      action: PayloadAction<{ layout: string; showLogo: boolean; showCampaign: boolean }>
    ): void {
      const { layout, showLogo, showCampaign } = action.payload;
      state.reportConfig = {
        layout,
        showLogo,
        showCampaign,
      };
    },
    toggleLogoSetting(state: WaterTestState): void {
      state.reportConfig = {
        ...state.reportConfig,
        showLogo: !state.reportConfig.showLogo,
      };
    },
    toggleCampaignSetting(state: WaterTestState): void {
      state.reportConfig = {
        ...state.reportConfig,
        showCampaign: !state.reportConfig.showCampaign,
      };
    },
  },
  extraReducers: (builder) => {
    builder.addCase(thunks.generateWaterTestReport.pending, (state: WaterTestState) => {
      state.generatingReport = 'pending';
      state.errorReport = null;
    });
    builder.addCase(thunks.generateWaterTestReport.fulfilled, (state: WaterTestState, action) => {
      // @ts-ignore
      const { data } = action.payload;
      state.labJob = data;
      state.generatingReport = 'succeeded';
      state.errorReport = null;
    });
    builder
      .addCase(thunks.generateWaterTestReport.rejected, (state: WaterTestState, action) => {
        state.generatingReport = 'failed';
        state.errorReport = null;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchPending, (state, action) => {
        state.isLoading = true;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchFulfilled, (state, action) => {
        state.labJob = action.payload;
      })
      .addMatcher(labJobApi.endpoints.getLabJob.matchRejected, (state, action) => {
        state.isLoading = false;
      });
  },
});

export const { reducer } = slice;

export const reset =
  (): AppThunk =>
  (dispatch): void => {
    dispatch(slice.actions.reset());
  };

export const openPreviewPdf =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.openPreviewPdf());
  };

export const closePreviewPdf =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.closePreviewPdf());
  };

export const changeLayout = (layout: string) => (dispatch) => {
  dispatch(slice.actions.changeLayout({ layout }));
};

export const toggleLogoSetting =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.toggleLogoSetting());
  };

export const toggleCampaignSetting =
  (): AppThunk =>
  async (dispatch): Promise<void> => {
    dispatch(slice.actions.toggleCampaignSetting());
  };

export const applyReportConfig =
  (layout: string, showLogo: boolean, showCampaign: boolean) => (dispatch) => {
    dispatch(slice.actions.applyReportConfig({ layout, showLogo, showCampaign }));
  };

export const changeDevice = (device: string) => (dispatch) => {
  dispatch(slice.actions.changeDevice({ device }));
};

export default slice;
